<template>
  <div id="location" class="chat-location">
    <div ref="point" class="point">
      <template v-if="address">
        <div class="notice">
          <div class="cont">
            <div class="des">{{ address }}</div>
          </div>
        </div>
        <img :src="locationIcon" class="icon">
      </template>
    </div>
    <div class="center" @click="goCenter">
      <img :src="centerIcon" class="img">
    </div>
    <div id="map" class="map" />
    <div v-if="isSelect" class="bottom">
      <van-button
        round
        block
        type="info"
        color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
        @click="selectLocation"
      >
        确定
      </van-button>
    </div>
    <div v-else class="bottom">
      <van-button
        round
        block
        type="info"
        color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
        @click="sendLocation"
      >
        发送
      </van-button>
    </div>
  </div>
</template>
<script>
import { initMap } from '@/utils/map'
import { base64Upload } from '@/api/file'
import centerIcon from '@/assets/location/relocate@2x.png'
import locationIcon from '@/assets/chatLocation/locate@2x.png'
import html2canvas from 'html2canvas'
export default {
  data() {
    return {
      map: null,
      AMap: null,
      AMapUI: null,
      marker: null,
      address: '',
      location: '',
      center: '',
      isSelect: false,
      locationIcon,
      centerIcon
    }
  },
  mounted() {
    this.isSelect = this.$route.query.isSelect
    this.initMap()
  },
  methods: {
    goCenter() {
      const { latitude, longitude } = this.location
      this.map.setCenter(new this.AMap.LngLat(longitude, latitude))
    },
    async selectLocation() {
      html2canvas(
        document.getElementById('location'),
        {
          useCORS: true, // 支持图片跨域
          scale: 1 // 设置放大的倍数
        }
      ).then(async canvas => {
        // 截图用img元素承装，显示在页面的上
        const imgBase64 = canvas.toDataURL('image/jpeg')// toDataURL :图片格式转成 base64
        const res = await base64Upload({ base64Image: imgBase64 })
        this.$store.dispatch('user/setSelectLocation', { address: this.address, lng: this.location.longitude, lat: this.location.latitude, locationImg: res.datas.url })
        this.$router.go(-1)
      })
    },
    async sendLocation() {
      html2canvas(
        document.getElementById('location'),
        {
          // backgroundColor: null, // 画出来的图片有白色的边框,不要可设置背景为透明色（null）
          useCORS: true, // 支持图片跨域
          scale: 1 // 设置放大的倍数
        }
      ).then(async canvas => {
        // 截图用img元素承装，显示在页面的上
        const imgBase64 = canvas.toDataURL('image/jpeg')// toDataURL :图片格式转成 base64
        const res = await base64Upload({ base64Image: imgBase64 })
        this.$store.dispatch('user/setLocationInfo', { status: true, address: this.address, lng: this.location.longitude, lat: this.location.latitude, locationImg: res.datas.url })
        this.$router.go(-1)
      })
    },
    async initMap() {
      const loading = this.$toast.loading({ forbidClick: true, message: '初始化...', duration: 0 })
      this.AMap = await initMap()
      loading.clear()
      this.map = new this.AMap.Map('map', {
        zoom: 16,
        WebGLParams: {
          preserveDrawingBuffer: true
        }
      })
      this.map.plugin('AMap.Geolocation', () => {
        const geolocation = new this.AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          showMarker: false, // 定位成功后在定位到的位置显示点标记，默认：true
          showCircle: false, // 定位成功后用圆圈表示定位精度范围，默认：true
          showButton: false
        })
        this.map.addControl(geolocation)
        const startLoactionLoading = this.$toast.loading({ forbidClick: true, message: '定位中...', duration: 0 })
        geolocation.getCurrentPosition(async(status, result) => {
          startLoactionLoading.clear()
          console.log(status, result, '======')
          if (status === 'complete') {
            // data是具体的定位信息
            const { lat, lng } = result.position
            this.AMap.plugin('AMap.Geocoder', () => {
              var geocoder = new this.AMap.Geocoder()
              this.location = { latitude: lat, longitude: lng }
              var lnglat = [lng, lat]
              const locationLoading = this.$toast.loading({ forbidClick: true, message: '定位中...', duration: 0 })
              geocoder.getAddress(lnglat, async(status, result) => {
                locationLoading.clear()
                if (status === 'complete' && result.info === 'OK') {
                  console.log(result, '=========111')
                  this.address = result.regeocode.formattedAddress
                  const center = new this.AMap.LngLat(lng, lat) // 获取当前地图中心点
                  this.marker = new this.AMap.Marker({
                    position: center,
                    anchor: 'center',
                    content: this.$refs.point
                  })
                  this.map.add(this.marker)
                }
              })
            })
          }
        }) // 定位
      })
      const setCenter = () => {
        const center = this.map.getCenter()
        this.location = { latitude: center.lat, longitude: center.lng }
        console.log(center.toString(), 'center')
        this.marker && this.marker.setPosition(new this.AMap.LngLat(center.lng, center.lat))
      }
      const getAddress = () => {
        const center = this.map.getCenter()
        const lnglat = [center.lng, center.lat]
        var geocoder = new this.AMap.Geocoder()
        geocoder.getAddress(lnglat, async(status, result) => {
          if (status === 'complete' && result.info === 'OK') {
            this.address = result.regeocode.formattedAddress
          }
        })
      }
      this.map.on('dragging', setCenter)
      this.map.on('zoomchange', setCenter)
      this.map.on('mapmove', setCenter)
      this.map.on('dragend', getAddress)
      this.map.on('zoomend', getAddress)
      this.map.on('moveend', getAddress)
    }
  }
}
</script>
<style lang="scss" scoped>
.chat-location {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9;
  .map {
    width: 100%;
    height: 100%;
  }
  .center {
    position: absolute;
    top: 50%;
    right: 7px;
    z-index: 99;
    .img {
      width: 68px;
      height: 68px;
    }
  }
  .bottom {
    position: fixed;
    bottom: 40px;
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
  }
}
.point {
  position: relative;
  .notice {
    display: flex;
    align-items: center;
    position: absolute;
    top: -50px;
    left: -90px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    background: #fff;
    .pic {
      .img {
        width: 44px;
        height: 42px;
      }
    }
    .cont {
      padding-left: 10px;
      width: 250px;
      .title {
        font-size: 16px;
        color: #2E3135;
        font-weight: bold;
        .num {
          padding: 0 5px;
          font-size: 16px;
          color: #FF7700;
        }
      }
      .des {
        padding-top: 6px;
        font-size: 12px;
        color: #333333;
      }
    }
  }
  .icon {
    width: 100px;
    height: 100px;
  }
}
</style>
